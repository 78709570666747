import React from "react";
import SEO from "@components/seo";
import Landing from "@components/Landing/Landing";

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Landing />
  </>
);

export default IndexPage
