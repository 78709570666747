import React, {useEffect} from 'react';
import './Landing.scss';

import {navigate} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faTwitter, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons';

import Section3Bg from '@backgrounds/landing-section-3.jpeg';
import Section4Bg from '@backgrounds/landing-section-4.png';
import Section5BgDesktop from '@backgrounds/landing-section-5-vector-desktop.svg';
import Section5BgMobile from '@backgrounds/landing-section-5-vector-mobile.svg';
import CerebroLogo from '@icons/ic-cerebro.svg';
import IcLanding1 from '@icons/ic-landing-1.svg';
import IcLanding2 from '@icons/ic-landing-2.svg';
import IcLanding3 from '@icons/ic-landing-3.svg';
import IcArrowRight from '@icons/ic-arrow-right.svg';
import {getToken} from "@repository/client";

function Landing() {
  const isLogined = getToken() ? true : false;

  const benefits = [
    {
      icon: IcLanding1,
      title: '1. Sharing Ideas',
      description: 'Future doctor should be, teaching method should be. ',
    },
    {
      icon: IcLanding2,
      title: '2. Gain knowledge',
      description: 'Cases and theories discussion, Book references and recommendation, learning way and time management.',
    },
    {
      icon: IcLanding3,
      title: '3. Promoting activities',
      description: 'Competitions, conferences, call for papers, seminars, exchange programs, and scholarships.',
    },
    {
      icon: IcLanding1,
      title: '4. Building Collaboration',
      description: 'Research team, campaign team.',
    },
    {
      icon: IcLanding2,
      title: '5. Discussing Issues',
      description: 'Health issues, medical education and profession issues.',
    },
    {
      icon: IcLanding3,
      title: '6. Chit-Chat',
      description: 'Movies and songs recommendation, stories, experiences.',
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <>
      <div id="landing-introduction" className="d-flex">
        <div className="w-100 h-100 position-absolute background" />
        <div className="container d-flex flex-column align-items-center justify-content-center m-auto">
          <img src={CerebroLogo} alt="cerebro logo" />
          <div className="dosis-large-title text-pure-white text-center mb-2">
            Connecting medical students worldwide
          </div>
          <div className="asap-subhead text-pure-white text-center mb-3 description">
            Cerebro is the home to medical students, endless conversations, and humanity connections.
          </div>
          <div className="d-flex flex-row">
            <button
              className="button-lg button-default text-philadelphia-blue mr-3"
              onClick={() => navigate('/mym')}>
              Meet Your Mentor
            </button>
            {isLogined ? (
              <button
                className="button-lg button-default-ghost "
                onClick={() => navigate('/browse')}>
                Browse Threads <i className="mdi mdi-arrow-right ml-1" />
              </button>
            ) : (
              <button
                className="button-lg button-default-ghost"
                onClick={() => navigate('/register')}>
                Register <i className="mdi mdi-arrow-right ml-1" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div id="landing-benefits" className="container py-5 my-3">
        <div className="dosis-title-1 text-philadelphia-blue my-4 text-center text-md-left title">
          What Cerebro Provides 
        </div>
        <div className="row">
          {benefits.map((benefit, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={`benefit-${index}`}>
              <div className="d-flex">
                <img src={benefit.icon} className="align-self-start" alt={`benefit ${index}`} />
                <div className="pl-3">
                  <div className="dosis-headline mb-2">{benefit.title}</div>
                  <div className="asap-body text-iron-gray">{benefit.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div id="landing-uniqueness">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 col-md-12 order-lg-1 order-2 bg-philadelphia-blue px-4 description-wrapper">
              <div className="dosis-title-1 text-pure-white">
                Why Cerebro is The Best Choice
              </div>
              <div className="divider bg-pure-white my-3" />
              <div className="asap-body text-pure-white">
                As a medical student, you must have millions of opinions and ideas in your head. You must have thousands of questions about medical theories, cases, or learning process that you want to ask for help. You must have hundreds of medical activities that you held in your university and want to share it to get more participants. You must need partners or suggestion to do a research. You must concern about health issues and want to discuss with someone. You should build relationship with other medical students to exchange expierience.
                <br />
                <br />
                Cerebro is the best choice for every single medical student to do all of it with free and fun, everywhere and everytime.
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-lg-2 order-1 pl-lg-0 d-flex justify-content-lg-start justify-content-center">
              <img src={Section3Bg} alt="section 3 background" />
            </div>
          </div>
        </div>
      </div>
      <div id="landing-countries" className="container py-5 my-5 position-relative">
        <img src={Section4Bg} alt="map" className="position-absolute background" />
        <div className="d-flex flex-column align-items-center justify-content-center py-5 my-5">
          <div className="dosis-title-1 text-center mb-3 title">
            Available in<br /><span className="text-philadelphia-blue">200+ Countries</span>
          </div>
          <div className="asap-subhead text-center description">
            Health problem is so complex. It can’t be done by one people or one country. Our body can be homeostasis because collaboration of each organ. Likewise, this world can be better due to collaboration of every single medical student in each country.
          </div>
        </div>
      </div>
      <div id="landing-social-medias" className="container mb-5">
        <div className="wrapper bg-philadelphia-blue p-4 position-relative">
          <img src={Section5BgDesktop} className="position-absolute img-section-5-desktop" />
          <img src={Section5BgMobile} className="position-absolute img-section-5-mobile" />
          <div className="row d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <div className="col-lg-5 mb-3 mb-md-0">
              <div className="dosis-title-1 text-pure-white title mb-3 mb-lg-0 text-center text-lg-left">
                Follow our latest health tips and discussions <img src={IcArrowRight} />
              </div>
            </div>
            <div className="col-lg-7 d-flex align-items-center justify-content-between justify-content-around">
              <div className="row">
                <div className="col-3">
                  <a href="https://www.facebook.com/medstudentnetwork" target="_blank">
                    <div className="d-flex flex-column align-items-center icon-wrapper">
                      <div className="bg-pure-white rounded-circle d-flex align-items-center justify-content-center mb-2 icon">
                        <FontAwesomeIcon icon={faFacebookF} size="2x" className="text-azure-blue" />
                      </div>
                      <div className="asap-body text-pure-white icon-text">Facebook</div>
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a href="https://twitter.com/medstudnetwork" target="_blank">
                    <div className="d-flex flex-column align-items-center icon-wrapper">
                      <div className="bg-pure-white rounded-circle d-flex align-items-center justify-content-center mb-2 icon">
                        <FontAwesomeIcon icon={faTwitter} size="2x" className="text-azure-blue" />
                      </div>
                      <div className="asap-body text-pure-white icon-text">Twitter</div>
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a href="https://www.instagram.com/cerebro.pro/" target="_blank">
                    <div className="d-flex flex-column align-items-center icon-wrapper">
                      <div className="bg-pure-white rounded-circle d-flex align-items-center justify-content-center mb-2 icon">
                        <FontAwesomeIcon icon={faInstagram} size="2x" className="text-azure-blue" />
                      </div>
                      <div className="asap-body text-pure-white icon-text">Instagram</div>
                    </div>
                  </a>
                </div>
                <div className="col-3">
                  <a href="https://www.youtube.com/channel/UCwB4c8l99GEpZH2YczH10Kg" target="_blank">
                    <div className="d-flex flex-column align-items-center icon-wrapper">
                      <div className="bg-pure-white rounded-circle d-flex align-items-center justify-content-center mb-2 icon">
                        <FontAwesomeIcon icon={faYoutube} size="2x" className="text-azure-blue" />
                      </div>
                      <div className="asap-body text-pure-white icon-text">Youtube</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
